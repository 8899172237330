//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  watchEffect,
  ref,
} from '@nuxtjs/composition-api'
import { useCurrentTime } from '~/helpers/current-time'

export default defineComponent({
  props: {
    target: String,
    keepZeros: {
      type: Boolean,
      default: false,
    },
    hideUnits: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { currentTime: now } = useCurrentTime(500)
    const target = new Date(props.target)

    const diff = computed(() =>
      Math.trunc((target.getTime() - now.value.getTime()) / 1000)
    )
    const days = computed(() =>
      Math.max(Math.trunc(diff.value / 60 / 60 / 24), 0)
    )
    const hours = computed(() =>
      Math.max(Math.trunc(diff.value / 60 / 60) % 24, 0)
    )
    const minutes = computed(() =>
      Math.max(Math.trunc(diff.value / 60) % 60, 0)
    )
    const seconds = computed(() => Math.max(Math.trunc(diff.value) % 60, 0))

    const dayLabel = computed(() => (days.value !== 1 ? 'Tagen' : 'Tag'))
    const hourLabel = computed(() => (hours.value !== 1 ? 'Stunden' : 'Stunde'))
    const minuteLabel = computed(() =>
      minutes.value !== 1 ? 'Minuten' : 'Minute'
    )
    const secondLabel = computed(() =>
      seconds.value !== 1 ? 'Sekunden' : 'Sekunde'
    )

    const ended = computed(() => diff.value < 0)

    const formatDigits = (value) => {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }

    return {
      days,
      formatDigits,
      hours,
      minutes,
      seconds,
      dayLabel,
      hourLabel,
      minuteLabel,
      secondLabel,
    }
  },
})
